<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">系统通知</div>
      <div class="back" @click="goback">返回上一页</div>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="input-box">
          <div class="title-box">阅读状态:</div>
          <el-select
            v-model="viewed"
            placeholder="请选择审批状态"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <button class="btn-pre" @click="getWorkflowTable(1)">
          <i class="fa fa-search"></i>查询
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          border
          height="500"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column prop="Title" label="标题"> </el-table-column>
          <el-table-column prop="WorkTable" label="审批业务表名">
          </el-table-column>
          <el-table-column prop="Viewed" label="是否查看"> </el-table-column>
          <!-- <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <i class="fa fa-search" @click="query(scope.row)"></i>
              <i class="fa fa-edit" @click="edit(scope.row)"></i>
              <i class="fa fa-trash"></i>
            </template>
          </el-table-column> -->
        </el-table>
        <el-pagination
          @size-change="getWorkflowTable(1)"
          @current-change="getWorkflowTable"
          :current-page.sync="currentPage"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="dialogType === 1 ? '审核' : '查询'"
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
      top="3vh"
    >
      <div class="dialog-main">
        <CostAllocation
          v-if="WorkTable === 'CostAllocation' && descriptions"
          :descriptions="descriptions"
        ></CostAllocation>
        <ManpowerRequest
          v-if="WorkTable === 'ManpowerRequest' && descriptions"
          :descriptions="descriptions"
        ></ManpowerRequest>
        <ManpowerSupplier
          v-if="WorkTable === 'ManpowerSupplier' && descriptions"
          :descriptions="descriptions"
        ></ManpowerSupplier>
        <ManpowerRecruitmentHourlyRate
          v-if="WorkTable === 'ManpowerRecruitmentHourlyRate' && descriptions"
          :descriptions="descriptions"
        ></ManpowerRecruitmentHourlyRate>
        <EmployeeOvertimeWorkingApplication
          v-if="
            WorkTable === 'EmployeeOvertimeWorkingApplication' && descriptions
          "
          :descriptions="descriptions"
        ></EmployeeOvertimeWorkingApplication>
        <EmployeeLeaveApplication
          v-if="WorkTable === 'EmployeeLeaveApplication' && descriptions"
          :descriptions="descriptions"
        ></EmployeeLeaveApplication>
        <div class="radios">
          <el-radio v-model="radio" label="1">审核</el-radio>
          <el-radio v-model="radio" label="2">审核记录</el-radio>
        </div>
        <!-- 审核 -->
        <div class="verify" v-if="radio == 1">
          <div class="v-left">
            <div class="v-steps">
              <div v-for="(item, index) in workFlowSteps" :key="index">
                <div
                  class="step-item"
                  :class="{
                    'step-item-ad':
                      item.AuditId || item.StepAttrType == 'start',
                  }"
                  v-if="item.StepAttrType == 'start'"
                >
                  <div class="left-item">
                    <div>流程开始</div>
                    <div class="left-date">{{ item.CreateDate }}</div>
                  </div>
                  <div class="right-item">
                    <div class="step-line"></div>
                    <i class="step-circle"></i>
                    <div class="step-title">
                      {{ item.StepName }}
                    </div>
                    <div class="step-text">发起人：{{ item.Creator }}</div>
                  </div>
                </div>
                <div class="step-item" v-else-if="item.StepAttrType == 'end'">
                  <div class="left-item">
                    <div>流程结束</div>
                  </div>
                  <div class="right-item">
                    <div class="step-line"></div>
                    <i class="step-circle"></i>
                    <div class="step-title">
                      {{ item.StepName }}
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  :class="{ 'step-current': item.IsCurrentStep }"
                  class="step-item"
                >
                  <div class="left-item">
                    <div>
                      {{
                        item.StepAttrType === "copy" ? "抄送时间" : "审批时间"
                      }}
                    </div>
                    <div class="left-date">
                      {{
                        item.AuditDate
                          ? item.AuditDate
                          : item.StepAttrType === "copy"
                          ? "待抄送"
                          : "待审批"
                      }}
                    </div>
                  </div>
                  <div class="right-item">
                    <div class="step-line"></div>
                    <i class="step-circle"></i>
                    <div class="step-title">
                      {{ item.StepName }}
                    </div>
                    <div v-if="!item.AuditList">
                      <div class="step-text">
                        {{
                          item.StepAttrType === "copy" ? "抄送人" : "审批人"
                        }}：{{ item.Auditor }}
                      </div>
                      <div class="step-text">
                        状 态：
                        {{ item.AuditStatus | filterName(AuditStatusDic) }}
                      </div>
                      <div class="step-text">
                        备 注： {{ item.Remark || "-" }}
                      </div>
                    </div>
                    <div
                      v-else
                      :class="[
                        'step-table',
                        { 'step-table-active': item.IsCurrentStep },
                      ]"
                    >
                      <el-table
                        :data="item.AuditList"
                        :header-cell-style="{
                          color: '#7A7676',
                          background: '#F5F7FA',
                        }"
                      >
                        <el-table-column
                          prop="StepValue"
                          :label="
                            item.StepAttrType === 'copy' ? '抄送者' : '分配者'
                          "
                          width="150"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="Auditor"
                          label="审批人"
                          v-if="item.StepAttrType !== 'copy'"
                          width="150"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="AuditStatus"
                          label="状态"
                          width="150"
                        >
                          <template slot-scope="scope">
                            <div v-if="item.StepAttrType !== 'copy'">
                              {{
                                scope.row.AuditStatus
                                  | filterName(AuditStatusDic)
                              }}
                            </div>
                            <div v-else>
                              {{ scope.row.AuditStatus > 0 ? "已抄送" : "" }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="AuditDate"
                          :label="
                            item.StepAttrType === 'copy'
                              ? '抄送时间'
                              : '审批时间'
                          "
                          width="150"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="SignatureUrl"
                          label="签名"
                          width="140"
                        >
                          <template slot-scope="scope">
                            <img
                              class="SignatureUrl"
                              :src="scope.row.SignatureUrl"
                            />
                          </template>
                        </el-table-column>
                      </el-table>
                      <div class="opinion" v-if="item.StepAttrType !== 'copy'">
                        <div>审批意见:</div>
                        <div>{{ item.AuditList | filterAuditList }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-right" v-if="isExamine && dialogType === 1">
            <div class="states-box">
              <div class="name">审批</div>
              <div class="states-radios">
                <el-radio-group v-model="statesRadio">
                  <el-radio label="1">通过</el-radio>
                  <el-radio label="3">拒绝</el-radio>
                  <el-radio label="4">驳回</el-radio>
                </el-radio-group>
              </div>
            </div>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="textarea"
            >
            </el-input>
            <el-button type="primary" icon="el-icon-check" @click="setAudit"
              >审批</el-button
            >
          </div>
        </div>
        <!-- 审核记录 -->
        <div class="record" v-else>
          <el-table :data="AuditLogs" :header-cell-style="{ color: '#4d4d4d' }">
            <el-table-column type="index" width="40"> </el-table-column>
            <el-table-column prop="StepName" label="节点" width="150">
            </el-table-column>
            <el-table-column prop="Auditor" label="审批人" width="100">
            </el-table-column>
            <el-table-column prop="AuditStatus" label="审批状态" width="100">
              <template slot-scope="scope">
                <div
                  v-if="scope.row.AuditStatus === 0"
                  class="label-primary approval"
                >
                  待审核
                </div>
                <div
                  v-if="scope.row.AuditStatus === 1"
                  class="label-success approval"
                >
                  审核通过
                </div>
                <div
                  v-if="scope.row.AuditStatus === 2"
                  class="label-info approval"
                >
                  审核中
                </div>
                <div
                  v-if="scope.row.AuditStatus === 3"
                  class="label-warning approval"
                >
                  审核未通过
                </div>
                <div
                  v-if="scope.row.AuditStatus === 4"
                  class="label-danger approval"
                >
                  驳回
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="AuditDate" label="审批时间" width="130">
            </el-table-column>
            <el-table-column prop="Remark" label="审批结果" width="140">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="转交审批"
      :visible.sync="dialogVisible2"
      width="800px"
      :before-close="handleClose2"
      top="13vh"
      class="transfer"
    >
      <div class="dialog-main2">
        <div class="line-box">
          <div class="from-box">
            <div class="name">流程实例:</div>
            <el-select
              v-model="WorkTable"
              @change="getWorkFlowTableStats"
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in exampleOptions"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>

          <div class="from-box">
            <div>共计可转办事项:{{ number }}条</div>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">转办人:</div>
            <el-select
              v-model="SystemAdminID"
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in userOptions"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose2">取消</el-button
        ><el-button class="pop-save" @click="setTransfer">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { auditRecords } from "@/utils/tableData";
import FlowPanel from "@/components/ef/panel";
import {
  getMessages,
  getWorkFlowTableStepsType,
  setAudit,
  getTables,
  getNodeDic,
  setTransfer,
  getWorkFlowTableStats,
} from "@/api/user";
import CostAllocation from "@/components/examine/CostAllocation.vue";
import ManpowerRequest from "@/components/examine/ManpowerRequest.vue";
import ManpowerSupplier from "@/components/examine/ManpowerSupplier.vue";
import ManpowerRecruitmentHourlyRate from "@/components/examine/ManpowerRecruitmentHourlyRate.vue";
import EmployeeOvertimeWorkingApplication from "@/components/examine/EmployeeOvertimeWorkingApplication.vue";
import EmployeeLeaveApplication from "@/components/examine/EmployeeLeaveApplication.vue";
export default {
  filters: {
    filterName(data, tables) {
      const item = tables.find((item) => {
        return item.Key === data;
      });
      return item ? item.Value : "";
    },
    filterAuditList(data) {
      let name = "无";
      data.forEach((item) => {
        if (item.Remark) {
          name = item.Remark;
        }
      });
      return name;
    },
  },
  data() {
    return {
      tableData: null,
      auditRecords: auditRecords,
      currentPage: 1,
      dialogVisible: false,
      dialogType: null,
      multipleSelection: [],
      viewed: "",
      options1: [
        {
          value: false,
          label: "未读",
        },
        {
          value: true,
          label: "已读",
        },
      ],

      senior: false,
      auditStatus: 0,
      filterAuditStatus: null,
      workflowName: null,
      workflowTableName: null,
      daterange: null,
      size: 10,
      radio: "1",
      statesRadio: "",
      textarea: "",
      workFlowSteps: [
        {
          auditId: "1",
          stepAttrType: "start",
          createDate: "2023-05-13 17:19:06",
          stepName: "流程开始",
          creator: "超级管理员",
          isCurrent: false,
          auditDate: "2023-05-13 17:19:06",
          auditor: null,
          auditStatus: 1,
          remark: "111",
        },
        {
          auditId: "2",
          stepAttrType: "node",
          createDate: "2023-05-13 17:19:06",
          stepName: "产品部>=7000审批",
          creator: "产品部",
          isCurrent: false,
          auditDate: "2023-05-13 17:19:06",
          auditor: "产品部",
          auditStatus: "已审批",
          remark: "111",
        },
        {
          auditId: "2",
          stepAttrType: "node",
          createDate: "2023-05-13 17:19:06",
          stepName: "产品部>=9000审批",
          creator: "产品部",
          isCurrent: true,
          auditDate: "2023-05-13 17:19:06",
          auditor: "产品部",
          auditStatus: "待审批",
          remark: "111",
        },
        {
          auditId: "3",
          stepAttrType: "end",
          createDate: "2023-05-13 17:19:06",
          stepName: "流程结束",
          creator: "超级管理员",
          isCurrent: false,
          auditDate: "2023-05-13 17:19:06",
          auditor: null,
          auditStatus: 1,
          remark: "111",
        },
      ],
      total: null,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      AuditStatusDic: [],
      AuditLogs: [],
      isExamine: false,
      WorkTableKey: null,
      WorkTable: null,
      descriptions: null,
      keyID: null,
      loading: true,
      dialogVisible2: false,
      exampleOptions: [],
      WorkTable: null,
      userOptions: [],
      SystemAdminID: null,
      number: 0,
    };
  },
  components: {
    FlowPanel,
    CostAllocation,
    ManpowerRequest,
    ManpowerSupplier,
    ManpowerRecruitmentHourlyRate,
    EmployeeOvertimeWorkingApplication,
    EmployeeLeaveApplication,
  },
  mounted() {
    if (this.$route.query.type) {
      this.auditStatus = parseInt(this.$route.query.type);
    }
    if (this.$route.query.key) {
      this.keyID = this.$route.query.key;
    }
    this.getWorkflowTable();
    // this.getButtonPower();
    this.getTables();
    this.getNodeDic();
  },
  methods: {
    goback() {
      this.$router.push("/ordinary");
    },
    deleteItem(index) {
      this.messageList.splice(index, 1);
    },
    handleNoticeClose() {
      this.noticeDrawer = false;
      this.statesRadio = "";
      this.textarea = "";
      this.WorkTableKey = null;
      this.WorkTable = null;
    },
    handleCurrentChange() {},
    handleSizeChange() {},
    handleClose() {
      this.dialogVisible = false;
      this.isExamine = false;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getWorkflowTable(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.currentPage,
        size: this.size,
        messageType: 4,
        viewed: this.viewed,
      };
      getMessages(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.senior = false;
        this.loading = false;
      });
    },
    //获取可转办条目
    getWorkFlowTableStats() {
      if (this.WorkTable) {
        getWorkFlowTableStats({ workTable: this.WorkTable }).then((res) => {
          if (res.status === 200) {
            this.number = res.response;
          }
        });
      } else {
        this.number = 0;
      }
    },
    //编辑
    edit(data) {
      this.dialogVisible = true;
      this.dialogType = 1;
      this.WorkTableKey = data.WorkTableKey;
      this.WorkTable = data.WorkTable;

      this.getWorkFlowTableSteps(data.WorkTableKey, data.WorkTable);
    },
    //查询
    query(data) {
      this.dialogVisible = true;
      this.dialogType = 2;
      this.getWorkFlowTableSteps(data.WorkTableKey, data.WorkTable);
    },
    //审批
    setAudit() {
      const data = {
        tableName: this.WorkTable,
        Id: 0,
        Key: this.WorkTableKey,
        AuditStatus: parseInt(this.statesRadio),
        Remark: this.textarea,
        Details: null,
      };
      if (this.WorkTable === "CostAllocation") {
        data.tableName = "CostAllocation" + this.descriptions.TypeID;
        if (
          this.descriptions.Details &&
          this.descriptions.Details.length !== 0
        ) {
          const arr = [];
          this.descriptions.Details.forEach((item) => {
            if (item.checkbox) {
              arr.push(item.KeyID);
            }
          });
          if (arr.length !== 0) {
            data.Details = arr;
          }
        }
      }
      setAudit(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("审批成功");
          this.dialogVisible = false;
          this.getWorkflowTable();
        }
      });
    },
    getWorkFlowTableSteps(id, tableName) {
      const Ids = [];
      Ids.push(id);
      getWorkFlowTableStepsType({
        Ids,
        WorkTableKey: tableName,
        type: "todo",
      }).then((res) => {
        if (res.status === 200) {
          this.workFlowSteps = res.response.Steps;
          this.AuditStatusDic = res.response.AuditStatusDic;
          this.AuditLogs = res.response.AuditLogs;

          if (tableName === "CostAllocation") {
            if (
              res.response.Form.Details &&
              res.response.Form.Details.length !== 0
            ) {
              res.response.Form.Details.forEach((_, index, array) => {
                array[index].checkbox = false;
              });
            }
          }
          res.response.Form.SubtableAction = res.response.SubtableAction;
          this.descriptions = res.response.Form;

          res.response.Steps.forEach((item) => {
            if (item.StepId === res.response.CurrentStepId) {
              this.isExamine = item.IsCurrentUser;
            }
          });
        }
      });
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //清空条件
    clearConditions() {
      this.auditStatus = null;
      this.filterAuditStatus = null;
      this.workflowName = null;
      this.workflowTableName = null;
      this.keyID = null;
      this.daterange = null;
    },

    //转交审批
    handleClose2() {
      this.dialogVisible2 = false;
      this.WorkTable = null;

      this.SystemAdminID = null;
    },

    //流程实例
    getTables() {
      getTables().then((res) => {
        if (res.status === 200) {
          this.exampleOptions = res.response;
        }
      });
    },
    //转办人
    getNodeDic() {
      getNodeDic().then((res) => {
        if (res.status === 200) {
          this.userOptions = res.response.users;
        }
      });
    },
    //
    setTransfer() {
      const data = {
        WorkTable: this.WorkTable,
        SystemAdminID: this.SystemAdminID,
      };
      setTransfer(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("转办成功");
          this.handleClose2();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .back {
      margin-left: 24px;
      color: #257eff;
      cursor: pointer;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 0;
    .screen-box {
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 12px;
      flex-wrap: wrap;
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-left: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
      button {
        margin-left: 24px;
        border: none;
        padding: none;
        padding: 6px 12px;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        margin-bottom: 24px;
        cursor: pointer;
        .fa {
          color: #fff;
          margin: 0;
          margin-right: 5px;
        }
      }
      .el-range-editor {
        margin-right: 24px;
      }
      .btn-pre {
        background-color: #3f729b;
        margin-left: 0;
      }
      .btn-pre:hover {
        background-color: #305777;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .input-box {
        display: flex;
        align-items: center;
        height: 34px;
        margin-bottom: 24px;
        .title-box {
          padding: 0 12px;
          border: 1px solid #d2d6de;
          line-height: 32px;
          border-right: none;
          color: #555;
          font-size: 14px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        // margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
    }
  }
  .dialog-main {
    padding: 10px;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding-bottom: 48px;
  }
  .radios {
    display: flex;
    margin: 24px 0;
  }
  .verify {
    display: flex;
    justify-content: space-between;
    .v-left {
      width: 65%;
    }
    .v-right {
      width: 35%;
      .states-box {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        .name {
          margin-right: 30px;
        }
      }
      .el-button {
        position: relative;
        margin-top: 24px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.step-table {
  margin-top: 10px;
  max-width: 400px;
  .opinion {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
  }
}
.dialog-main2 {
  border-bottom: 2px solid #e6e6e6;
  padding: 12px 24px 0 24px;
  width: 100%;
  box-sizing: border-box;
  .line-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .from-box {
      display: flex;
      align-items: center;
      width: 370px;
      margin-bottom: 24px;
      .name {
        width: 110px;
        font-size: 16px;
        flex-shrink: 0;
        margin-right: 10px;
      }
      .el-input {
        width: 250px;
      }
      .el-select {
        width: 250px;
      }
    }
    .single {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 24px;
      .name {
        flex-shrink: 0;
        width: 110px;
        margin-right: 10px;
      }
      .el-cascader {
        width: 100%;
      }
    }
  }
}
.dialog-footer {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  height: 85vh;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.transfer >>> .el-dialog__body {
  padding: 0;
  height: auto;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.step-table-active >>> .cell {
  color: #2f95ff !important;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
<style lang="scss" scoped>
.audit-model-content {
  padding: 10px;
}

.step-item {
  background: #fff;
  display: flex;
}

.left-item {
  min-width: 180px;
  text-align: right;
  padding-right: 25px;
  padding-top: 8px;

  .left-date {
    font-size: 13px;
    padding-top: 7px;
    color: #6c6c6c;
  }
}

.right-item {
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #f3f3f3;
  padding: 5px 0 5px 5px;
}

.left-item,
.right-item {
  padding-bottom: 10px;
}

.right-item:last-child {
  border-bottom: 0;
}

.step-line {
  top: 16px;
  left: -10px;
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: #ebedf0;
}

.step-circle {
  position: absolute;
  top: 17px;
  left: -9px;
  z-index: 2;
  font-size: 12px;
  line-height: 1;
  transform: translate(-50%, -50%);
  width: 7px;
  height: 7px;
  background-color: #a1a1a1;
  border-radius: 50%;
}

.right-item::before {
  content: "";
}

.step-content {
  padding-top: 2px;
  font-size: 14px;
  color: #828282;
  line-height: 1.5;
}

.step-title {
  font-weight: bold;
  padding-top: 3px;
}

.step-text {
  font-size: 13px;
  color: #999999;
  padding-top: 6px;
}

.step-current {
  * {
    color: #2f95ff !important;
  }

  .step-circle {
    background: #2f95ff !important;
  }

  // border-radius: 5px;
  // border: 1px solid #d6eaff;
  font-size: 13px;
  padding-top: 6px;
  // background-color: #eff7ffd9;
  color: black;
}

.audit-content {
  // background: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  display: flex;

  .fx-left {
    flex: 1;
    width: 0;

    .rd {
      display: flex;
      align-items: baseline;
    }
  }

  .fx-right {
    // width: 400px;

    .btn {
      margin-top: 10px;
      text-align: center;
    }
  }
}

.cell-item {
  font-weight: 500;
}

.desc-top {
  padding: 5px 10px 0 10px;
}
.step-item-ad {
  * {
    color: #9f9898 !important;
  }
}
.SignatureUrl {
  width: 100%;
}
</style>
